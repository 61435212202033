import { createEmitter } from '@iheartradio/web.utilities/create-emitter';
import type { Logger } from '@iheartradio/web.utilities/create-logger';

import { createAmazonHeaderBidder } from './amazon/index.js';
import { createLiveRampHeaderBidder } from './live-ramp/index.js';
import { createRubiconHeaderBidder } from './rubicon/index.js';
import type { HeaderBiddingConfig } from './types.js';

export const createHeaderBidding = ({ logger }: { logger: Logger }) => {
  let optOutStatus = false;
  /**
   * As more header bidders come online, instantiate them here and then call the appropriate methods
   * in the emitter below
   */
  const amazonHeaderBidder = createAmazonHeaderBidder({ logger });
  const liveRampHeaderBidder = createLiveRampHeaderBidder({ logger });
  const rubiconHeaderBidder = createRubiconHeaderBidder({ logger });

  const headerBidders = createEmitter({
    async initialize(config: HeaderBiddingConfig) {
      logger.info('Initializing header bidders');
      if (config.privacyOptOut) {
        optOutStatus = true;
      }
      if (!optOutStatus) {
        await Promise.allSettled([
          amazonHeaderBidder.initialize(config),
          liveRampHeaderBidder.initialize(config),
          rubiconHeaderBidder.initialize(config),
        ]);
        logger.info('Header bidders initialized');
      } else {
        logger.info(
          'Privacy opt out enabled, skipping header bidder initialization',
        );
      }
    },
    async fetchBids(slots: googletag.Slot[]) {
      if (!optOutStatus) {
        logger.info('Fetching header bids');
        await Promise.allSettled([
          amazonHeaderBidder.fetchBids(slots),
          rubiconHeaderBidder.fetchBids(slots),
        ]);
        logger.info('Header bids fetched');
      } else {
        logger.info('Privacy opt out enabled, skipping header bids fetching');
      }
    },
  });

  return headerBidders;
};
